import React from 'react';
import Card from './Card';
import './CardGrid.css';

const CardGrid = ({ data }) => {
  // Divide os dados em grupos de 5 para criar as colunas.
  const columns = data.reduce((acc, current, index) => {
    const columnIndex = index % 5;
    acc[columnIndex] = [...(acc[columnIndex] || []), current];
    return acc;
  }, []);

  return (
    <div className="card-grid">
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} className="column">
          {column.map((item) => (
            <Card
             key={item.id}
             id={item.id}
             sku={item.SKU}
             nome={item.NOME}
             conteudo={item['﻿COD GERAL']}
             imagem={item.IMAGEM}
              />
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardGrid;