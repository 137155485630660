import React from 'react';
import './LogoCentered.css'; // Importe o arquivo CSS com os estilos

const LogoCentered = () => {
  return (
    <div className="outer-div">
      <div className="centered-div">
        <img src="https://www.megaborrj.com.br/wp-content/uploads/2023/06/megabor-logo-transparente.png" alt="Logo" />
      </div>
    </div>
  );
};

export default LogoCentered;
