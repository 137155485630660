import React from 'react';
import './Card.css';

import { BsExclamationTriangle } from "react-icons/bs";


// BsExclamationTriangle


const Card = ({ id, sku, nome, preco, imagem }) => {
  const imagemNaoEncontrada = <BsExclamationTriangle />; // Coloque o caminho da imagem padrão aqui
  return (
    <div className="card">
      {imagem ? (        
        <img src={imagem} alt={`Imagem do item ${id}`} />
      ) : (
        <div>{imagemNaoEncontrada}</div>
      )}
      <div className="card-content">
        <h1>{id}</h1>
        <h3>SKU: {sku}</h3>
        <h2>{nome}</h2>               
      </div>
    </div>
  );
};

export default Card;
