import { useState,useEffect } from 'react';
import CardGrid from './components/CardGrid';
import LogoCentered from './LogoCentered';
//import jsonData from './data/dados.json';
import axios from 'axios';
import './App.css';

const ITEMS_PER_PAGE = 25; // 5 colunas x 5 linhas = 25 itens por página

const App = () => {
  // foi onde eu mexi no code 
  const [jsonData, setJsonData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://megaborrj.com.br/data/dados.json');
        setJsonData(response.data);
        console.log(response.data);
        
      } catch (error) {
        console.error('Error na busca do json:', error);
        
        
      }
    };

    fetchData();
  }, []);



  // aqui eu parei 



  // Dividir o array de objetos em pedaços menores
  const paginatedData = [];
  for (let i = 0; i < jsonData.length; i += ITEMS_PER_PAGE) {
    paginatedData.push(jsonData.slice(i, i + ITEMS_PER_PAGE));
  }

  // Estado para controlar a página atual
  const [currentPage, setCurrentPage] = useState(0);

  // Função para ir para a próxima página
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para ir para a página anterior
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="centered-container"> {/* Adicionamos a classe 'centered-container' aqui */}
      <div className="painel">
        <LogoCentered />
        <h1>Catálogo 2023</h1>
        <CardGrid data={paginatedData[currentPage]} />

        {/* Paginação */}
        <div className="pagination">
          <button onClick={prevPage} disabled={currentPage === 0}>
            Anterior
          </button>
          <span>Página {currentPage + 1}</span>
          <button onClick={nextPage} disabled={currentPage === paginatedData.length - 1}>
            Próxima
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;

